/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

const thumbSize = {
  height: 'auto',
  maxWidth: '235px',
  maxHeight: '115px',
  minHeight: '115px',
  objectFit: 'cover',
  width: '100%',
};
const imgSize = {
  height: 'auto',
  maxWidth: '230px',
  minHeight: '115px',
  objectFit: 'cover',
  width: '100%',
};

export const extractImg = html => {
  const imgPat = new RegExp(/<img.*?src="(.*?)"\salt="(.*?)"\s?>/, 'g');
  const cleanImgPat = new RegExp(
    /^(\/\/)(.*)(\.png|jpg|jpeg|svg|webp)(\?.*)?$/,
    'g'
  );
  const foundImg = html.match(imgPat);
  const img = foundImg ? foundImg[0] : null;
  const text = foundImg ? html.replace(img, '') : html;
  const replacer = (match, p1, p2) => {
    const src = p1.replace(cleanImgPat, 'https://$2$3');

    return `<img src="${src}" alt="${p2}" />`;
  };

  return {
    img: img ? img.replace(imgPat, replacer) : null,
    text,
  };
};

/**
 * Insights page Feed component. Data sourced from Contentful.
 * @component
 *
 * @param   {array} posts - array of contentful blog posts
 * @param   {string} activeType - filter by insights type
 * @returns {component} - <Feed currTag={string} />
 */

const Feed = ({ posts, activeType }) => {
  const [currTag, setCurrTag] = useState(activeType);

  useEffect(() => {
    if (activeType) {
      const lower = activeType.toLowerCase();
      setCurrTag(lower);
    }
  }, [activeType]);

  return (
    <ul
      className={cx(base.ma0, base.w100, main.ln, main.recent)}
      data-type={currTag}
    >
      {posts.map(({ node }, index) => {
        const { content, header, hero, heroImage, slug, summary, type } = node;
        const brief = summary || content || hero;
        const heroImg = hero ? extractImg(hero.childMarkdownRemark.html) : null;
        const active = currTag !== 'all' ? currTag === type[0] : true;

        const classes = cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyStart,
          base.ma0,
          base.pa0,
          base.w100,
          main.post,
          theme.shapeWhite
        );
        return (
          <li
            key={`${index}-${slug}`}
            className={classes}
            data-active={`${active}`}
          >
            <Link
              to={`/insights/${slug}`}
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.postThumb
              )}
            >
              {heroImage ? (
                <Img
                  fluid={heroImage.fluid}
                  alt="Insights thumbnail"
                  style={thumbSize}
                  imgStyle={imgSize}
                />
              ) : heroImg && heroImg.img ? (
                <CleanHTML html={heroImg.img} />
              ) : null}
            </Link>
            <article
              className={cx(base.flex, base.flexColumn, base.itemsStart)}
            >
              <p className={cx(base.mb2, base.mt0, base.ttc, main.postType)}>
                {type[0]}
              </p>
              <h2
                className={cx(
                  base.mb2,
                  base.mt0,
                  main.postTitleSm,
                  theme.title3
                )}
              >
                <Link to={`/insights/${slug}`} className={theme.colorDark}>
                  <CleanHTML html={header.childMarkdownRemark.html} />
                </Link>
              </h2>
              <div className={main.postExcerpt}>
                <CleanHTML html={brief.childMarkdownRemark.excerpt} />
              </div>
            </article>
          </li>
        );
      })}
    </ul>
  );
};

Feed.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        superheader: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            html: PropTypes.string,
          }),
        }),
        header: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            html: PropTypes.string,
          }),
        }),
        heroImage: PropTypes.shape({
          fluid: PropTypes.shape({}),
        }),
        hero: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            excerpt: PropTypes.string,
            html: PropTypes.string,
            rawMarkdownBody: PropTypes.string,
          }),
        }),
        type: PropTypes.arrayOf(PropTypes.string),
        slug: PropTypes.string,
        content: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            excerpt: PropTypes.string,
            html: PropTypes.string,
          }),
        }),
        summary: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            excerpt: PropTypes.string,
            html: PropTypes.string,
          }),
        }),
      }),
    })
  ).isRequired,
  activeType: PropTypes.string.isRequired,
};

export default Feed;
