import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useScript from './util/useScript';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';

const WistiaVideo = ({ videoID, nobody = false }) => {
  useScript(`https://fast.wistia.com/embed/medias/${videoID}.jsonp`);
  useScript('https://fast.wistia.com/assets/external/E-v1.js');

  return (
    <div
      className={cx(base.w100, main.ctaVidContainer)}
      data-nobody={`${!nobody}`}
    >
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <div
            className={`wistia_embed wistia_async_${videoID} seo=false videoFoam=true`}
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: 0,
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                transition: 'opacity 200ms',
                width: '100%',
              }}
            >
              <img
                src={`https://fast.wistia.com/embed/medias/${videoID}/swatch`}
                style={{
                  filter: 'blur(5px)',
                  height: '100%',
                  objectFit: 'contain',
                  width: '100%',
                }}
                alt=""
                aria-hidden="true"
                onLoad="this.parentNode.style.opacity=1;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WistiaVideo.propTypes = {
  videoID: PropTypes.string.isRequired,
  nobody: PropTypes.bool,
};

WistiaVideo.defaultProps = {
  nobody: false,
};

export default WistiaVideo;
