import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import check from '../../images/check-mark.svg';

/**
 * Insights hero signup NetlifyForm component.
 * @component
 *
 * @returns {component} - <SignupHero title={string} formName={string} />
 */

const SignupHero = ({
  title,
  insightTitle,
  subtitle,
  buttonLabel,
  formName,
  mediaTitle,
  setUnlocked,
  gated,
  alt,
}) => {
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  // mark event as unlocked
  useEffect(() => {
    if (gated) {
      setUnlocked(true);
    }
  }, [gated]);

  return (
    <aside
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.mba,
        base.w100,
        main.contactFormWrap,
        main.contactFormInsightsHero
      )}
      data-alt={`${alt}`}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyCenter,
          base.mb3,
          base.w100
        )}
      >
        <h2 className={cx(base.f3, base.mb1, base.tc, theme.colorDark)}>
          {title}
        </h2>
        <p className={cx(base.f4, base.mb0, base.tc, theme.colorTextGrey)}>
          {subtitle}
        </p>
      </div>
      <NetlifyForm
        name={formName}
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyStart,
          base.w100,
          main.contactForm
        )}
        honeypotName="bot-field"
      >
        {({ handleChange, success, submitting }) => {
          if (success) {
            setSent(true);
          }

          return (
            <>
              <Honeypot />
              <input
                id="title"
                name="title"
                type="hidden"
                value={insightTitle}
                onChange={handleChange}
              />
              <input
                id="media"
                name="media"
                type="hidden"
                value={mediaTitle}
                onChange={handleChange}
              />
              <input
                id="category"
                name="category"
                type="hidden"
                value="Insights"
                onChange={handleChange}
              />
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.relative,
                  base.w100
                )}
              >
                <label
                  htmlFor="email"
                  className={cx(
                    base.mb1,
                    base.srOnly,
                    theme.colorTextGrey,
                    theme.title4
                  )}
                >
                  Email
                  <span aria-hidden="true" className={theme.colorDanger}>
                    {' '}
                    *
                  </span>
                </label>
                <input
                  id="email"
                  data-testid="email"
                  className={cx(base.w100, main.formInputField)}
                  type="email"
                  name="email"
                  inputMode="email"
                  placeholder="Your work email"
                  required
                  onChange={evt => {
                    handleChange(evt);
                    validateEmail(evt);
                  }}
                  data-valid={`${!invalidEmail}`}
                />
                <span
                  className={cx(main.emailValidationError)}
                  aria-hidden={!invalidEmail}
                  style={{ display: invalidEmail ? 'block' : 'none' }}
                >
                  Invalid Email
                </span>
              </div>
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.w100
                )}
              >
                <label
                  htmlFor="name"
                  className={cx(
                    base.mb1,
                    base.srOnly,
                    theme.colorTextGrey,
                    theme.title4
                  )}
                >
                  Name
                  <span aria-hidden="true" className={theme.colorDanger}>
                    {' '}
                    *
                  </span>
                </label>
                <input
                  id="name"
                  data-testid="name"
                  className={cx(base.w100, main.formInputField)}
                  type="name"
                  name="name"
                  inputMode="name"
                  placeholder="Your name"
                  required
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                data-testid="submit"
                className={cx(
                  base.w100,
                  main.button,
                  main.contactButton,
                  theme.colorWhite,
                  theme.dpBtnBlue
                )}
                disabled={submitting || sent || invalidEmail}
              >
                {submitting ? (
                  'Sending...'
                ) : sent ? (
                  <img
                    src={check}
                    alt=""
                    aria-hidden="true"
                    className={main.jobFormCheck}
                  />
                ) : (
                  buttonLabel
                )}
              </button>
            </>
          );
        }}
      </NetlifyForm>
    </aside>
  );
};

SignupHero.propTypes = {
  title: PropTypes.string.isRequired,
  insightTitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  mediaTitle: PropTypes.string,
  setUnlocked: PropTypes.func.isRequired,
  gated: PropTypes.bool.isRequired,
  alt: PropTypes.bool,
};

SignupHero.defaultProps = {
  alt: false,
  mediaTitle: 'none',
};

export default SignupHero;
