import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { Link } from 'gatsby';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import Social from './social';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import check from '../../images/check-mark.svg';
import TalkArrow from '../../images/talk-arrow.svg';

/**
 * Insights page signup NetlifyForm component.
 * @component
 *
 * @returns {component} - <SignupUpdates />
 */

const SignupUpdates = ({ url }) => {
  // eslint-disable-next-line no-unused-vars
  const [formName, setFormName] = useState('Insights Sign Up');
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  return (
    <aside
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsStart,
        base.justifyCenter,
        base.pb4,
        base.pt4,
        base.w100,
        main.contactFormWrapSm
      )}
    >
      <h2 className={cx(base.mb3, theme.colorDark, main.signupHeading)}>
        Sign up for updates
      </h2>
      <NetlifyForm
        name={formName}
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyStart,
          base.w100,
          main.contactForm
        )}
        honeypotName="bot-field"
      >
        {({ handleChange, success, submitting }) => {
          if (success) {
            setSent(true);
          }

          return (
            <>
              <Honeypot />
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.relative,
                  base.w100
                )}
              >
                <label
                  htmlFor="email"
                  className={cx(
                    base.mb1,
                    base.srOnly,
                    theme.colorTextGrey,
                    theme.title4
                  )}
                >
                  Email
                  <span aria-hidden="true" className={theme.colorDanger}>
                    {' '}
                    *
                  </span>
                </label>
                <input
                  id="email"
                  data-testid="email"
                  className={cx(base.w100, main.formInputField)}
                  type="email"
                  name="email"
                  inputMode="email"
                  placeholder="Your work email"
                  required
                  onChange={evt => {
                    handleChange(evt);
                    validateEmail(evt);
                  }}
                  data-valid={`${!invalidEmail}`}
                />
                <span
                  className={cx(main.emailValidationError)}
                  aria-hidden={!invalidEmail}
                  style={{ display: invalidEmail ? 'block' : 'none' }}
                >
                  Invalid Email
                </span>
              </div>
              <button
                type="submit"
                data-testid="submit"
                className={cx(main.button, main.dpBtnInverted)}
                disabled={submitting || sent || invalidEmail}
              >
                {submitting ? (
                  'Subscribing...'
                ) : sent ? (
                  <img
                    src={check}
                    alt=""
                    aria-hidden="true"
                    className={main.jobFormCheck}
                  />
                ) : (
                  'Subscribe'
                )}
              </button>
            </>
          );
        }}
      </NetlifyForm>
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.mb3,
          base.mt3,
          base.pb1,
          base.pt1,
          base.w100
        )}
      >
        <Link
          to="/contact"
          className={cx(base.link, base.mr3, main.signupTalk)}
        >
          Talk to the author
        </Link>
        <img src={TalkArrow} alt="Twitter Link" />
      </div>
      <Social url={url} />
    </aside>
  );
};

SignupUpdates.propTypes = {
  url: PropTypes.string.isRequired,
};

export default SignupUpdates;
