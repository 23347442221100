import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { format } from 'date-fns';
import { graphql } from 'gatsby';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import CleanHTML from '../components/cleanHTML';
import Layout from '../components/layout';
import SignupUpdates from '../components/insights/signupUpdates';
import SignupHero from '../components/insights/signupHero';
import useLocalStorage from '../components/util/useLocalStorage';
import { extractImg } from '../components/insights/insightsFeed';
import WistiaVideo from '../components/wistiaVideo';

const types = [
  'News',
  'Events',
  'Webinars',
  'guides',
  'Blog',
  'Tools',
  'Recoded Video',
  'Case Studies',
];

const heroImgWrap = {
  maxWidth: '554px',
  maxHeight: '334px',
  objectFit: 'cover',
  width: '100%',
};

const heroImgSty = {
  ...heroImgWrap,
  objectPosition: 'center bottom',
};

const mediaImgWrap = {
  height: 'auto',
  maxWidth: '315px',
  objectFit: 'cover',
  width: '100%',
};

const mediaImgSty = {
  ...mediaImgWrap,
  objectPosition: 'center bottom',
};

const typeDeco = {
  backgroundColor: '#ffd449',
};

const typeDivider = {
  ...typeDeco,
  height: '18px',
  margin: '0 16px',
  width: '5px',
};

const typeDot = {
  ...typeDeco,
  borderRadius: '50%',
  height: '12px',
  marginRight: '20px',
  width: '12px',
};

export const query = graphql`
  query PostQuery($slug: String!) {
    contentfulInsights(slug: { eq: $slug }) {
      superheader {
        childMarkdownRemark {
          excerpt(format: PLAIN, pruneLength: 120, truncate: true)
          html
        }
      }
      header {
        childMarkdownRemark {
          excerpt(format: PLAIN, pruneLength: 120, truncate: true)
          html
        }
      }
      date
      author {
        childMarkdownRemark {
          html
        }
      }
      summary {
        childMarkdownRemark {
          excerpt(format: PLAIN, pruneLength: 200, truncate: true)
          html
        }
      }
      heroImage {
        fluid(maxHeight: 150, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      hero {
        childMarkdownRemark {
          excerpt(format: PLAIN, pruneLength: 120, truncate: true)
          html
        }
      }
      type
      media {
        file {
          contentType
          url
        }
        title
      }
      mediaPreview {
        fluid(maxWidth: 400, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      mediaSize
      wistiaVideo
      content {
        childMarkdownRemark {
          excerpt(format: PLAIN, pruneLength: 120, truncate: true)
          html
        }
      }
    }
    epub: imageSharp(fluid: { originalName: { regex: "/epub_placeholder/" } }) {
      fluid(maxWidth: 400, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    pdf: imageSharp(fluid: { originalName: { regex: "/pdf_placeholder/" } }) {
      fluid(maxWidth: 400, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const dashToCamelCase = str =>
  str
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/-(.)/g, (match, group1) => group1.toUpperCase());

const TypeLabel = ({ type, date, mediaSize }) => {
  const typesList = types.reduce(
    (arr, item) => ({
      ...arr,
      [item]: { lower: item.toLowerCase(), upper: item },
    }),
    {}
  );
  const nonEmptyType = Boolean(type) && type !== '' && type !== ' ';

  switch (type) {
    case typesList.Blog.lower:
      return (
        <span className={cx(base.mb2)}>
          {nonEmptyType ? type : 'Insights Blog'}
        </span>
      );
    case typesList.News.lower:
      return (
        <span className={cx(base.mb2)}>{nonEmptyType ? type : 'News'}</span>
      );
    default:
      return (
        <div
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.mb2
          )}
        >
          <div style={typeDot} />
          <p cx={cx(base.ma0, main.typeLabel)}>{type}</p>
          {mediaSize || date ? (
            <>
              <div style={typeDivider} />
              {mediaSize ? (
                <p cx={cx(base.ma0)} style={{ textTransform: 'lowercase' }}>
                  {mediaSize}
                </p>
              ) : (
                <p cx={cx(base.ma0)}>{date}</p>
              )}
            </>
          ) : null}
        </div>
      );
  }
};

/**
 * Insights page Single template component.
 * @component
 *
 * @param   {object} data     - blog post copy and info object
 * @param   {string} location - URL data object
 * @returns {component}       - <Single data={object} location={string} />
 */
const Single = ({ data, location }) => {
  const [expandHero, setExpandHero] = useState(false);
  const {
    contentfulInsights: {
      author,
      content,
      date,
      header,
      hero,
      heroImage,
      media,
      mediaSize,
      mediaPreview,
      summary,
      superheader,
      type,
      wistiaVideo,
    },
    epub,
    pdf,
  } = data;
  const pageTitle = superheader
    ? `${superheader.childMarkdownRemark.excerpt} - ${header.childMarkdownRemark.excerpt}`
    : header.childMarkdownRemark.excerpt;
  const extAuthor = author ? extractImg(author.childMarkdownRemark.html) : null;
  const authorImg = extAuthor ? extAuthor.img : null;
  const authorName = extAuthor
    ? extAuthor.text
        .replace(/<\/?p>/g, '')
        .replace(/^\n/g, '')
        .split('\n')
    : null;
  const cleanMediaUrl = media
    ? media.file.url.replace(/^(\/\/)(.*)/g, 'https:$1$2')
    : null;
  const mediaPre = () => {
    switch (true) {
      case Boolean(mediaPreview):
        return mediaPreview.fluid;
      case media && media.file.contentType.includes('pdf'):
        return pdf.fluid;
      case media && media.file.contentType.includes('epub'):
        return epub.fluid;
      default:
        return null;
    }
  };
  const raw = new Date(date);
  const locale = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  })
    .format(raw)
    .replace(/.*,\s([A-Z]+)$/g, '$1');
  const iso = raw.toISOString().substring(0, 10);
  const relDate = format(raw, 'MMMM dd, yyyy');
  const relTime = format(raw, 'h:mm a');
  const fmtDate = date.includes('00:00')
    ? relDate
    : `${relDate} @ ${relTime} ${locale}`;
  const isPastDate = raw < new Date();
  const extHeroImg = hero
    ? extractImg(hero.childMarkdownRemark.html).img
    : null;
  const heroImg = heroImage ? heroImage.fluid.src : hero ? extHeroImg : null;
  const cleanImgPat = new RegExp(
    /^<img\s?src="(.*)(\.png|jpg|jpeg|svg|webp)"(.*)\/>?$/,
    'g'
  );
  const imgUrl = heroImg ? heroImg.replace(cleanImgPat, '$1$2') : null;
  const imgType = heroImg ? heroImg.replace(cleanImgPat, '$2') : null;
  const postImage = {
    height: '330',
    url: imgUrl,
    type: imgType,
    width: '800',
  };
  const description = summary || content || hero;
  const fancyType =
    type[0] !== 'blog' && type[0] !== 'news' && type[0] !== 'case studies';
  const signupHeroForm =
    type[0] === 'guides' || type[0] === 'events' || type[0] === 'webinars';
  const pastEventNoForm = type[0] === 'events' && isPastDate;
  const gated = type[0] === 'guides' || type[0] === 'webinars';
  const formData = {
    guides: {
      title: 'Get the guide',
      subtitle: 'Enter your info for access.',
      buttonLabel: 'Read now',
      formName: 'insightsEbook',
    },
    events: {
      title: 'Reserve your spot',
      subtitle: 'Enter your info to request an invite to this event.',
      buttonLabel: 'Sign up',
      formName: 'insightsEvent',
    },
    webinars: {
      title: 'Watch this video on-demand',
      subtitle: 'Enter your info to view the video.',
      buttonLabel: 'Watch now',
      formName: 'insightsVideo',
    },
  };
  const formTitle = Object.keys(formData).includes(type[0])
    ? formData[type[0]].title
    : null;
  const formLabel = formTitle ? dashToCamelCase(formTitle) : null;
  const hasHero =
    hero &&
    hero.childMarkdownRemark.html !== ' ' &&
    extractImg(hero.childMarkdownRemark.html).text !== '<p></p>';
  const wistiaCodePattern = new RegExp(/.*?([a-zA-Z0-9]+)$/, 'g');
  const wistiaCode = wistiaVideo
    ? wistiaVideo.replace(wistiaCodePattern, '$1')
    : null;

  // eslint-disable-next-line no-unused-vars
  const [unlocked, setUnlocked] = useLocalStorage(
    `${formLabel}-unlocked`,
    false
  );

  useEffect(() => {
    if (
      pastEventNoForm ||
      (type[0] === 'webinars' && wistiaVideo && unlocked)
    ) {
      setExpandHero(true);
    } else {
      setExpandHero(false);
    }
  }, [unlocked]);

  return (
    <Layout
      location={location}
      pageTitle={pageTitle}
      pageDescription={description.childMarkdownRemark.excerpt}
      postPublishDate={iso}
      postImage={postImage}
    >
      <article
        className={cx(base.w100, main.mt0, base.mb6, main.postSingle)}
        data-type={type[0]}
        data-nobody={`${Boolean(!content)}`}
      >
        <section
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.pl3,
            base.pr3,
            base.w100,
            main.insightsHeader,
            theme.colorBorderGrey
          )}
          data-lean={fancyType}
        >
          <div
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.w100,
              main.postHeroWrap
            )}
          >
            <div
              className={cx(
                base.f7,
                base.flex,
                base.flexColumn,
                base.itemsStart,
                base.justifyStart,
                base.mb2,
                base.mt0,
                base.ttc,
                main.superheader,
                theme.colorWhite,
                theme.title3
              )}
            >
              {fancyType ? (
                <TypeLabel
                  type={type[0]}
                  date={fmtDate}
                  mediaSize={mediaSize}
                />
              ) : null}
              {superheader ? (
                <CleanHTML html={superheader.childMarkdownRemark.html} />
              ) : null}
            </div>
            <h1
              className={cx(base.mb4, base.mw100, main.insightsTitle)}
              data-lean="true"
            >
              <CleanHTML html={header.childMarkdownRemark.html} />
            </h1>
            {!fancyType && author ? (
              <div
                className={cx(base.lhSolid, base.ma0, main.insightsAuthor)}
                data-img={`${Boolean(authorImg)}`}
              >
                <span className={main.srh}>Written by </span>
                {authorImg && <CleanHTML html={authorImg} />}
                <ul
                  className={cx(
                    base.flex,
                    base.flexColumn,
                    base.itemsStart,
                    base.justifyStart,
                    base.w100
                  )}
                >
                  {authorName.map(name => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div
            className={cx(
              base.flex,
              base.itemsEnd,
              base.justifyCenter,
              base.w100,
              main.postHeroImgWrap
            )}
            data-description={Boolean(hero)}
            data-alt={fancyType}
            data-grid={`${signupHeroForm}`}
          >
            {type[0] === 'guides' && media ? (
              <a
                href={unlocked ? cleanMediaUrl : null}
                download={unlocked ? media.title : null}
                target={unlocked ? '_blank' : ''}
                rel="noopener noreferrer"
                data-active={`${unlocked}`}
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyCenter,
                  base.w100,
                  main.mediaAttachment
                )}
              >
                <Img
                  fluid={mediaPre()}
                  alt={media.title}
                  style={mediaImgWrap}
                  imgStyle={mediaImgSty}
                />
              </a>
            ) : null}
            {hasHero && (
              <div
                className={cx(base.mb0, base.mt0, main.postHeroImgDesc)}
                data-unlocked={`${type[0] === 'webinars' || expandHero}`}
              >
                <CleanHTML
                  html={extractImg(hero.childMarkdownRemark.html).text}
                />
                {type[0] === 'guides' && media && unlocked ? (
                  <a
                    href={unlocked ? cleanMediaUrl : null}
                    download={unlocked ? media.title : null}
                    target={unlocked ? '_blank' : ''}
                    rel="noopener noreferrer"
                    data-active={`${unlocked}`}
                    className={cx(
                      base.w100,
                      main.button,
                      main.contactButton,
                      theme.colorWhite,
                      theme.dpBtnBlue,
                      main.mediaLink
                    )}
                  >
                    Read Now
                  </a>
                ) : null}
              </div>
            )}
            {!fancyType && heroImage ? (
              <Img
                fluid={heroImage.fluid}
                alt={header.childMarkdownRemark.excerpt}
                style={heroImgWrap}
                imgStyle={heroImgSty}
              />
            ) : extHeroImg ? (
              <div className={cx(main.insightsHeroImgWrap)}>
                <CleanHTML html={extHeroImg} />
              </div>
            ) : null}
            {signupHeroForm &&
              !unlocked &&
              !pastEventNoForm &&
              type[0] !== 'webinars' && (
                <SignupHero
                  title={formTitle}
                  insightTitle={pageTitle}
                  subtitle={formData[type[0]].subtitle}
                  buttonLabel={formData[type[0]].buttonLabel}
                  formName={formData[type[0]].formName}
                  mediaTitle={media ? media.title : null}
                  setUnlocked={setUnlocked}
                  gated={gated}
                  alt={type[0] === 'guides'}
                />
              )}
            {type[0] === 'webinars' && (media || wistiaVideo) ? (
              wistiaVideo ? (
                <WistiaVideo videoID={wistiaCode} />
              ) : (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  width="960"
                  className={cx(base.w100, main.insightsVideo)}
                  controls
                  data-nobody={`${!content}`}
                >
                  <source src={cleanMediaUrl} type={media.file.contentType} />
                  Your browser does not support the video tag.
                </video>
              )
            ) : null}
          </div>
        </section>
        <div
          className={cx(
            base.flex,
            base.itemsStart,
            base.justifyBetween,
            base.w100,
            main.postWrap,
            main.px60
          )}
          data-empty={`${Boolean(!content)}`}
        >
          <section className={cx(base.pt4, base.w100, main.postBody)}>
            {content ? (
              <CleanHTML html={content.childMarkdownRemark.html} />
            ) : null}
          </section>
          {!fancyType ? <SignupUpdates url={location.href} /> : null}
        </div>
      </article>
    </Layout>
  );
};

TypeLabel.propTypes = {
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  mediaSize: PropTypes.string,
};

TypeLabel.defaultProps = {
  mediaSize: null,
};

Single.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    contentfulInsights: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    epub: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    pdf: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
};

export default Single;
