import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Facebook from '../../images/facebook.svg';
import LinkedIn from '../../images/linkedin.svg';
import Twitter from '../../images/twitter.svg';

/**
 * Social sharing component.
 * @component
 *
 * @returns {component} - <Social />
 */

const Social = ({ url }) => (
  <div
    className={cx(
      base.flex,
      base.itemsCenter,
      base.justifyStart,
      base.w100,
      main.socialWrap
    )}
  >
    <p className={cx(base.ma0)}>Share:</p>
    <ul
      className={cx(base.flex, base.itemsCenter, base.justifyStart, base.w100)}
    >
      <li>
        <a
          className={cx(base.flex, base.itemsCenter, base.justifyCenter)}
          href={`https://twitter.com/intent/tweet?text=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cx(base.srOnly)}>Decision Point H C on Twitter</span>
          <img src={Twitter} alt="Twitter Link" />
        </a>
      </li>
      <li>
        <a
          className={cx(base.flex, base.itemsCenter, base.justifyCenter)}
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cx(base.srOnly)}>Decision Point on Linked In</span>
          <img src={LinkedIn} alt="Linked In Link" />
        </a>
      </li>
      <li>
        <a
          className={cx(base.flex, base.itemsCenter, base.justifyCenter)}
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cx(base.srOnly)}>Decision Point on Facebook</span>
          <img src={Facebook} alt="Facebook Link" />
        </a>
      </li>
    </ul>
  </div>
);

Social.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Social;
